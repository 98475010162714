<template>
  <a-select :disabled="diasbled" style="width: 100%" v-model="unitType" @change="handleChange">
    <a-select-option v-for="(item, index) in unitTypeList" :key="index" :value="item.code">
      {{ item.name }}
    </a-select-option>
  </a-select>
</template>
<script>
export default {
  props: {
    diasbled: {
      type: Boolean,
      default: false,
    },
    code: {
      type: String,
    },
  },
  data() {
    return {
      unitType: undefined,
    }
  },
  computed: {
    unitTypeList() {
      return [
        {
          code: 'BASE',
          name: this.$t('基准单位'),
        },
        {
          code: 'SALE',
          name: this.$t('销售单位'),
        },
        {
          code: 'PROCUREMENT',
          name: this.$t('采购单位'),
        },
        {
          code: 'INVENTORY',
          name: this.$t('库存单位'),
        },
        {
          code: 'OTHER',
          name: this.$t('其他单位'),
        },
      ]
    },
  },
  mounted() {
    this.setDefault()
  },
  watch: {
    code() {
      this.setDefault()
    },
  },
  methods: {
    setDefault() {
      if (this.code && this.code !== 'undefined') {
        this.unitType = this.code
      } else {
        this.unitType = undefined
      }
    },
    handleChange(value) {
      this.unitType = value
      this.$emit('update:code', value)
      let data = this.unitTypeList.find((item) => item.code === value)
      this.$emit('change', data)
    },
  },
}
</script>
